@import '../../style/themes/index';
@import '../../style/mixins/index';
@import './affix';
@import './mixin';

// Input styles
.@{ant-prefix}-input {
  .reset-component();
  .input();
}

//== Style for input-group: input with label, with button or dropdown...
.@{ant-prefix}-input-group {
  .reset-component();
  .input-group(~'@{ant-prefix}-input');
  &-wrapper {
    display: inline-block;
    width: 100%;
    text-align: start;
    vertical-align: top; // https://github.com/ant-design/ant-design/issues/6403
  }
}

// Input with affix: prefix or suffix
.@{ant-prefix}-input-affix-wrapper {
  .reset-component();
  .input-affix-wrapper(~'@{ant-prefix}-input');
}

.@{ant-prefix}-input-password-icon {
  color: @text-color-secondary;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: @input-icon-hover-color;
  }
}

.@{ant-prefix}-input-clear-icon {
  .clear-icon();
  vertical-align: 0;
}

.@{ant-prefix}-input-clear-icon-hidden {
  visibility: hidden;
}

.@{ant-prefix}-input-textarea-clear-icon-hidden {
  visibility: hidden;
}

.@{ant-prefix}-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  .@{ant-prefix}-input {
    padding: @input-padding-vertical-base @input-padding-horizontal-base;
  }
}

.@{ant-prefix}-input-textarea-clear-icon {
  .clear-icon();
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 8px 0 0;
}

.@{ant-prefix}-input-textarea {
  &-show-count::after {
    display: block;
    color: @text-color-secondary;
    text-align: right;
    content: attr(data-count);
  }
}

@import './search-input';
